const onDocumentReady = () => {

// document.addEventListener("trix-attachment-add", function(event) {
//     if (!event.attachment.file) {
//       console.log(event.attachment + 'wird removed')
//       event.attachment.remove()
//       customWarning('Achtung','Bilder bitte manuell über den Button hinzufügen', 'warning');
//       $(".cyquer").append('<div class="position-relative"><div class="alert alert-primary alert-info position-fixed animate__animated animate__fadeOut animate__delay-5s" id="pictureNotAllowed"><span><p>Bilder bitte manuell über den Button hinzufügen.</p></span></div></div>')
//       $('#pictureNotAllowed').fadeIn();
//       // setTimeout(() => {$('#pictureNotAllowed').addClass('animate__animated animate__fadeOut');}, 5000);
//       setTimeout(() => {$(".cyquer").empty();}, 6000);
//     }
//   });

  /*Check in text-area if something has changed*/
document.addEventListener('trix-change', function () {
  var element = document.querySelector('trix-editor');
  var notifications_title = document.getElementById('notifications-title');
    if (notifications_title.value.length > 0) {
      var change = document.getElementById('trix-submit');
      change.classList.remove('disabled');
  }
});

  /*Check in title if something has changed*/

$('#notifications-title').on('input', function() {
  var element = document.querySelector("trix-editor");
    if (element.textContent != '') {
      $('#trix-submit').removeClass('disabled');
    }
});

/* Check if select has changed */
$('#notification_notification_type').on('change', function(){
  if ($('#notifications-title')!='' && $('#notification-content')!='' ){
    var element = document.querySelector("trix-editor");
      if (element.textContent != '') {
          $('#trix-submit').removeClass('disabled');
        }
  }
});

// $(document).on('trix-file-accept', function() {
// console.log(event.file.size)
//
//   if (event.file.size > 10485760) {
//       event.preventDefault()
//       customError('Dateiupload','Es dürfen nur Dateien mit max. 20 Mb hochgeladen werden.','warning');
//     }
//
//
// });

};


$(document).on('turbolinks:load', onDocumentReady);
